import { cn } from "#client/common/utils";
import LogoImage from "#client/images/logo.png";

export function Logo(props: { logoStyle?: string; textStyle?: string }) {
  return (
    <div className="flex shrink-0 items-center">
      <div className="flex items-center text-gray-900">
        <div className={cn("h-7 w-7", props.logoStyle)}>
          <img src={LogoImage} alt="logo" />
        </div>
        <span
          className={cn(
            "ml-2 font-dm_serif_text text-2xl font-bold",
            props.textStyle
          )}
        >
          EventShare
        </span>
      </div>
    </div>
  );
}
